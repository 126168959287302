<!-- 笔记管理 -->
<template>
  <div class="note">
    <el-card>
      <el-row class="condition" style="margin-bottom: 10px">
        <div class="conitem">
          <span class="title">所属任务</span>
          <el-select
            class="select"
            v-loadmore="loadMore"
            filterable
            v-model="condition.missionId"
            placeholder="请选择"
            size="mini"
          >
            <el-option
              :key="index"
              :label="item.title"
              :value="item.id"
              v-for="(item, index) in allprompts"
            >
            </el-option>
          </el-select>
        </div>
        <div class="conitem">
          <span class="title">投放类型</span>
          <el-select
            class="select"
            clearable
            v-model="condition.fansLevel"
            placeholder="请选择"
            size="mini"
          >
            <el-option label="素人" :value="0"> </el-option>
            <el-option label="KOC" :value="1"> </el-option>
            <el-option label="KOL" :value="2"> </el-option>
            <el-option label="植入" :value="3"> </el-option>
            <el-option label="买断" :value="4"> </el-option>
          </el-select>
        </div>
        <div class="conitem">
          <span class="title">笔记来源</span>
          <el-select
            class="select"
            clearable
            v-model="condition.category"
            placeholder="请选择"
            size="mini">
            <el-option label="平台用户" :value="0"> </el-option>
            <el-option label="供应商" :value="2"> </el-option>
            <el-option label="媒介" :value="3"> </el-option>
            <!-- <el-option label="植入" :value="1"> </el-option>
            <el-option label="买断" :value="4"> </el-option> -->
          </el-select>
        </div>
        <div class="conitem">
          <span class="title">是否报备</span>
          <el-select
            class="select"
            clearable
            v-model="condition.reportStatus"
            placeholder="请选择"
            size="mini"
          >
            <el-option label="水下" :value="0"> </el-option>
            <el-option label="水上" :value="1"> </el-option>
          </el-select>
        </div>
        <div class="conitem">
          <span class="title">笔记状态</span>
          <el-select
            class="select"
            clearable
            v-model="condition.validate"
            placeholder="请选择"
            size="mini"
          >
            <el-option label="有效" value="有效"> </el-option>
            <el-option label="无效" value="无效"> </el-option>
          </el-select>
        </div>
        <div class="conitem">
          <span class="title">是否导入</span>
          <el-select
            class="select"
            clearable
            v-model="condition.source"
            placeholder="请选择"
            size="mini"
          >
            <el-option label="平台笔记" value="0"> </el-option>
            <el-option label="导入笔记" value="1"> </el-option>
          </el-select>
        </div>
        <el-button type="primary" plain size="mini" @click="resetData"
          >重置</el-button
        >
      </el-row>
      <el-row class="condition">
        <div class="conitem">
          <span class="title">笔记标题</span>
          <el-input
            class="select"
            clearable
            v-model="condition.title"
            placeholder="请选择"
            size="mini"
          >
          </el-input>
        </div>
        <div class="conitem">
          <span class="title">达人名称</span>
          <el-input
            class="select"
            clearable
            v-model="condition.xhsNickName"
            placeholder="请选择"
            size="mini"
          >
          </el-input>
        </div>
        <div class="conitemlarge">
          <span class="title">导入日期</span>
          <el-date-picker
            class="select"
            size="mini"
            v-model="condition.notedate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </div>
        <div class="conitemlarge">
          <span class="title">总互动数</span>
          <el-input
            class="input"
            clearable
            v-model="condition.minTotal"
            placeholder="最小"
            size="mini"
          >
          </el-input
          >~
          <el-input
            class="input"
            clearable
            v-model="condition.maxTotal"
            placeholder="最大"
            size="mini"
          >
          </el-input>
        </div>

        <el-button type="primary" plain size="mini" @click="searchNote(true)"
          >查询笔记</el-button
        >
      </el-row>
    </el-card>
    <el-card class="noteList">
      <el-row class="title">
        <span class="first">笔记列表</span>
        <div class="btngroup">
          <el-button
            icon="el-icon-plus"
            plain
            size="mini"
            @click="showadd = true"
            >新增</el-button
          >
          <el-button
            icon="el-icon-download"
            plain
            size="mini"
            @click="showimport = true"
            >导入</el-button
          >
          <el-button
            icon="el-icon-upload2"
            plain
            size="mini"
            @click="exportNote"
            >导出</el-button
          >
          <el-button
            icon="el-icon-upload2"
            plain
            size="mini"
            @click="exportNotebyid"
            v-if="this.multipleSelection.length > 0"
            >导出选中</el-button
          >
          <el-button
            icon="el-icon-refresh"
            plain
            size="mini"
            @click="renewnoteDataFun"
            >更新笔记数据</el-button
          >
        </div>
      </el-row>
      <div class="body">
        <el-table
          v-loading="loading"
          :key="tableKey"
          :data="tableData"
          height="100%"
          @selection-change="handleSelectionChange"
          border
          style="width: 100%"
          :default-sort="{ prop: 'totalNum', order: 'descending' }"
        >
          <el-table-column type="selection" width="50" align="center">
          </el-table-column>
          <el-table-column
            label="发布日期"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.putDate }}
            </template>
          </el-table-column>

          <el-table-column
            label="投放类型"
            width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.fansLevel === 0">素人</span>
              <span v-else-if="scope.row.fansLevel === 1">KOC</span>
              <span v-else-if="scope.row.fansLevel === 2">KOL</span>
              <span v-else-if="scope.row.fansLevel === 3">植入</span>
              <span v-else-if="scope.row.fansLevel === 4">买断</span>
            </template>
          </el-table-column>
          <el-table-column
            label="达人昵称"
            width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.nickName }}
            </template>
          </el-table-column>
           <el-table-column
            label="主页链接"
            width="80"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <a :href="scope.row.homePage" target="_blank">链接</a>
            </template>
          </el-table-column>
          <el-table-column
            label="粉丝数"
            width="80"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
             <span> {{ scope.row.fansNum }}</span>
            </template>
          </el-table-column>
           <el-table-column
            label="笔记标题"
            width="200"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.title }}
            </template>
          </el-table-column>
          <el-table-column
            label="笔记链接"
            width="80"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <a :href="scope.row.annex" target="_blank">链接</a>
            </template>
          </el-table-column>
         
          <el-table-column
            label="笔记状态"
            width="120"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <el-tag
                :disable-transitions="true"
                :type="scope.row.validate === '有效' ? '' : 'danger'"
                >{{ scope.row.validate ? scope.row.validate : "无效" }}</el-tag
              >
            </template>
          </el-table-column>
          
          
          <el-table-column
            label="是否报备"
            width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.reportStatus === 0 ? "水下" : "水上" }}
            </template>
          </el-table-column>
          
          <el-table-column
            width="100"
            label="点赞"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.likeNum }}
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            label="收藏"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
             <span> {{ scope.row.collectNum }}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            label="评论"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.commentNum }}
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            label="总数"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.totalNum }}
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            label="备注"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.remark }}
            </template>
          </el-table-column>
          <el-table-column
            label="笔记来源"
            width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.category === 0">平台用户</span>
              <!-- <span v-else-if="scope.row.category === 1">植入</span> -->
              <span v-else-if="scope.row.category === 2">供应商</span>
              <span v-else-if="scope.row.category === 3">媒介</span>
              <!-- <span v-else-if="scope.row.category === 4">买断</span> -->
            </template>
          </el-table-column>
           <el-table-column
            width="100"
            label="创建时间"
            prop="createdOn"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.createdOn }}
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            label="更新时间"
            prop="updatedOn"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row.updatedOn }}
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="200"
            align="center"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span
                @click="editNote(scope.row)"
                style="cursor: pointer"
                v-if="scope.row.category !== 0"
                >编辑</span
              >
              <el-divider
                v-if="scope.row.category !== 0"
                direction="vertical"
              ></el-divider>
              <span
                @click="deletenoteData(scope.row.id)"
                style="cursor: pointer"
                v-if="scope.row.category !== 0"
                >删除</span
              >
              <el-divider
                v-if="scope.row.category !== 0"
                direction="vertical"
              ></el-divider>
              <span @click="detail(scope.row)" style="cursor: pointer"
                >查看详情</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <span>共<b>{{ condition.total }}</b>项数据</span>
        <span>总点赞：<b>{{ condition.likeTotal }}</b></span>
        <span>总收藏：<b>{{ condition.collectTotal }}</b></span>
        <span>总评论：<b>{{ condition.commentTotal }}</b></span>
        <span>总互动：<b>{{ condition.interactionTotal }}</b></span>
        <el-pagination
          style="margin-left: auto"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="condition.pageSize"
          :current-page.sync="condition.pageNo"
          @size-change="searchNote"
          @current-change="searchNote"
          background
          layout="sizes,prev, pager, next"
          :total="condition.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 笔记导入dialog -->
    <el-dialog
      :visible.sync="showimport"
      width="600px"
      center
      :close-on-click-modal="false"
      class="importdialog"
    >
      <template slot="title">
        <div class="dialog-title">笔记批量导入</div>
      </template>
      <el-upload
        class="elupload"
        drag
        :headers="mytoken"
        ref="dragupload"
        :data="uploadData"
        :limit="1"
        :auto-upload="false"
        action="/api/zbMissionAnnex/import"
        multiple
        :on-success="successupload"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          <em>点击</em>或将文件拖拽到这里上传
          <p style="color: #999999; line-height: 200%">支持扩展名：xls</p>
        </div>
        <div class="el-upload__tip" slot="tip">最大上传一个文件</div>
      </el-upload>
      <div class="mt-10px">
        <span class="text-14px fw-600 pr-20px! inline-block">下载导入模板</span>
        <a
          href="https://linggan-1316937351.cos.ap-shanghai.myqcloud.com/excel-template.xlsx"
          class="text-12px! c-#5874EF!"
          >笔记导入模板.xls</a
        >
      </div>
      <div class="conitem">
          <span class="title">是否需要爬取粉丝数</span>
          <el-select
            class="select"
            clearable
            v-model='condition.needFansNum'
            placeholder="请选择"
            size="mini"
          >
            <el-option label="不需要" value=false> </el-option>
            <el-option label="需要" value=true> </el-option>
          </el-select>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" plain @click="showimport = false"
          >取 消</el-button
        >
        <el-button
          size="mini"
          style="background-color: #5874ef; color: white"
          plain
          @click="importNote"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 笔记新增的dialog -->
    <el-dialog
      :visible.sync="showadd"
      width="400px"
      center
      :close-on-click-modal="false"
      class="importdialog"
    >
      <template slot="title">
        <div class="dialog-title">新增笔记</div>
      </template>
      <el-form
        :model="addnote"
        :rules="addrules"
        ref="addnote"
        label-width="100px"
        size="mini"
      >
        <el-form-item label="笔记来源" prop="category">
          <el-select
            style="width: 80%"
            v-model="addnote.category"
            placeholder="请选择笔记来源"
            clearable
          >
            <!-- <el-option label="平台用户" :value="0"> </el-option> -->
            <el-option label="供应商" :value="2"> </el-option>
            <el-option label="媒介" :value="3"> </el-option>
            <!-- <el-option label="植入" :value="1"> </el-option>
            <el-option label="买断" :value="4"> </el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="所属任务" prop="missionId">
          <el-select
            style="width: 80%"
            v-model="addnote.missionId"
            placeholder="请选择所属任务"
            clearable
          >
            <el-option
              :key="index"
              :label="item.title"
              :value="item.id"
              v-for="(item, index) in allprompts"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投放类型" prop="fansLevel">
          <el-select
            style="width: 80%"
            v-model="addnote.fansLevel"
            placeholder="请选择投放类型"
            clearable
          >
            <el-option label="素人" :value="0"> </el-option>
            <el-option label="KOC" :value="1"> </el-option>
            <el-option label="KOL" :value="2"> </el-option>
            <el-option label="植入" :value="3"> </el-option>
            <el-option label="买断" :value="4"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否报备" prop="reportStatus">
          <el-select
            style="width: 80%"
            v-model="addnote.reportStatus"
            placeholder="请选择笔记来源"
            clearable
          >
            <el-option label="水下" :value="0"> </el-option>
            <el-option label="水上" :value="1"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="笔记链接" prop="annex">
          <el-input
            clearable
            style="width: 80%"
            v-model="addnote.annex"
            placeholder="请输入笔记链接"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" plain @click="showadd = false">取 消</el-button>
        <el-button
          style="background-color: #5874ef; color: white"
          plain
          @click="addNote"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 笔记编辑的dialog -->
    <el-dialog
      :visible.sync="showedit"
      width="400px"
      center
      :close-on-click-modal="false"
      class="importdialog"
    >
      <template slot="title">
        <div class="dialog-title">编辑笔记</div>
      </template>
      <el-form
        :model="editnote"
        :rules="addrules"
        ref="addnote"
        label-width="100px"
        size="mini"
      >
        <el-form-item label="笔记来源" prop="category">
          <el-select
            style="width: 80%"
            v-model="editnote.category"
            placeholder="请选择笔记来源"
            clearable
          >
            <!-- <el-option label="平台用户" :value="0"> </el-option> -->
            <el-option label="供应商" :value="2"> </el-option>
            <el-option label="媒介" :value="3"> </el-option>
            <!-- <el-option label="植入" :value="1"> </el-option>
            <el-option label="买断" :value="4"> </el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="所属任务" prop="missionId">
          <el-select
            style="width: 80%"
            v-model="editnote.missionId"
            placeholder="请选择所属任务"
            clearable
          >
            <el-option
              :key="index"
              :label="item.title"
              :value="item.id"
              v-for="(item, index) in allprompts"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投放类型" prop="fansLevel">
          <el-select
            style="width: 80%"
            v-model="editnote.fansLevel"
            placeholder="请选择投放类型"
            clearable
          >
            <el-option label="素人" :value="0"> </el-option>
            <el-option label="KOC" :value="1"> </el-option>
            <el-option label="KOL" :value="2"> </el-option>
            <el-option label="植入" :value="3"> </el-option>
            <el-option label="买断" :value="4"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否报备" prop="reportStatus">
          <el-select
            style="width: 80%"
            v-model="editnote.reportStatus"
            placeholder="请选择笔记来源"
            clearable
          >
            <el-option label="水下" :value="0"> </el-option>
            <el-option label="水上" :value="1"> </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" plain @click="showedit = false">取 消</el-button>
        <el-button
          style="background-color: #5874ef; color: white"
          plain
          @click="editnoteData"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 笔记详情的dialog -->
    <el-dialog
      :visible.sync="showdetail"
      width="80%"
      center
      :close-on-click-modal="false"
      class="importdialog"
    >
      <template slot="title">
        <div class="dialog-title">{{ echartData.name }} 互动数据详情</div>
      </template>
      <el-date-picker
        size="mini"
        v-model="echartData.echartDate"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="gethistoryData"
        value-format="yyyy-MM-dd 00:00:00"
      >
      </el-date-picker>
      <div style="height: 400px" id="myechart"></div>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { loadmore } from "@/config/directives";
import { getList } from "@/api/home";
import {
  getnoteData,
  getnoteTotal,
  addnoteData,
  exportnoteData,
  exportnoteDatabyid,
  renewnoteData,
  deletenoteData,
  updatenoteData,
  getnotehistroy,
} from "@/api/audit";
import moment from "moment";
import * as echarts from "echarts";
export default {
  directives: {
    loadmore,
  },
  components: {},

  data() {
    return {
      condition: {
        missionId: null,
        fansLevel: null,
        category: null,
        reportStatus: null,
        validate: null,
        title: null,
        xhsNickName: null,
        maxTotal: null,
        minTotal: 0,
        pageNo: 0,
        pageSize: 10,
        total: 0,
        notedate: "",
        startAt: null,
        endAt: null,
        needFansNum: null
      },
      addnote: {
        annex: "",
        category: null,
        fansLevel: null,
        missionId: null,
        reportStatus: null,
      },
      editnote: {
        id: "",
        category: null,
        fansLevel: null,
        missionId: null,
        reportStatus: null,
      },
      addrules: {
        category: [
          { required: true, message: "请选择笔记来源", trigger: "change" },
        ],
        fansLevel: [
          { required: true, message: "请选择投放类型", trigger: "change" },
        ],
        missionId: [
          { required: true, message: "请选择所属任务", trigger: "change" },
        ],
        reportStatus: [
          { required: true, message: "请选择报备状态", trigger: "change" },
        ],
        annex: [
          { required: true, message: "请填写笔记链接", trigger: "change" },
        ],
      },
      promptpageNo: 1,
      allprompts: [],
      interactionTotal:0,
      likeTotal:0,
      commentTotal:0,
      collectTotal:0,
      // 判断是否可以继续下拉数据
      alloadMore: true,
      tableData: [],
      loading: false,
      tableKey: true,
      showimport: false,
      showadd: false,
      showedit: false,
      showdetail: false,
      echartData: {
        echartDate: [
          moment(new Date()).subtract(1, "month").format("yyyy-MM-DD 00:00:00"),
          moment(new Date()).format("yyyy-MM-DD 00:00:00"),
        ],
        id: null,
        name: null,
        xdata: [],
        totalNum: [],
        likeNum: [],
        commentNum: [],
        collectNum: [],
      },
      multipleSelection: [],
    };
  },
  created() {},
  async mounted() {
    await this.getList();
    this.condition.missionId = this.allprompts[0].id;
    await this.searchNote(false);
  },
  watch: {},
  computed: {
    mytoken() {
      return {
        "x-auth-token": localStorage.getItem("token"),
      };
    },
    uploadData() {
      return {
        missionId: this.condition.missionId,
        needFansNum: !this.condition.needFansNum ? 'false' : 'true',
      };
    },
  },
  methods: {
    // 所有的任务列表
    async getList() {
      await getList({ pageNo: this.promptpageNo, pageSize: 20 }).then((res) => {
        if (res.data.records.length > 0) {
          this.allprompts = [...this.allprompts, ...res.data.records];
          this.alloadMore = true;
        } else {
          this.alloadMore = false;
        }
      });
    },
    // 加载更多
    loadMore() {
      if (this.alloadMore) {
        this.promptpageNo = this.promptpageNo + 1;
        this.getList();
      }
    },
    // 重置数据
    resetData() {
      this.condition = {
        missionId: this.allprompts[0].id,
        fansLevel: null,
        category: null,
        reportStatus: null,
        validate: null,
        title: null,
        xhsNickName: null,
        maxTotal: null,
        minTotal: 0,
        pageNo: 0,
        pageSize: 10,
        notedate: "",
        startAt: null,
        endAt: null,
        total: 0,
      };

      this.searchNote();
    },
    async searchNote(val) {
      this.loading = true;
      if (this.condition.notedate) {
        this.condition.startAt = this.condition.notedate[0];
        this.condition.endAt = this.condition.notedate[1];
      } else {
        this.condition.startAt = null;
        this.condition.endAt = null;
      }
      await getnoteTotal(this.condition)
        .then((res) => {
          this.condition.interactionTotal = res.data.interactionTotal;
          this.condition.likeTotal = res.data.likeTotal;
          this.condition.commentTotal = res.data.commentTotal;
          this.condition.collectTotal = res.data.collectTotal;
        })

      await getnoteData(this.condition)
        .then((res) => {
          this.tableData = res.data.records;
          this.loading = false;
          this.tableKey = !this.tableKey;
          this.condition.total = res.data.total;
          if (val) {
            this.$message({
              message: "成功查询笔记",
              type: "success",
            });
          }
        })
        .catch((err) => {
          this.tableData = [];
          this.loading = false;
          this.tableKey = !this.tableKey;
        });
    },

    // 新增笔记
    addNote: _.throttle(function () {
      this.$refs.addnote.validate((valid) => {
        if (valid) {
          addnoteData(this.addnote).then((res) => {
            if (res.code === 1000) {
              this.$message({
                message: "成功新增笔记",
                type: "success",
              });
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    }, 1000),
    // 导入内容
    importNote() {
      if (this.condition.missionId) {
         this.$refs.dragupload.submit();
      } else {
        this.$message({
          message: "请选择所属任务再进行导入",
          type: "warning",
        });
      }
    },
    successupload(response) {
      if (response.code === 1000) {
        this.$message({
          message: "成功导入笔记",
          type: "success",
        });
        this.showimport = false;
        this.searchNote();
        this.$refs.dragupload.clearFiles();
      } else {
        this.$message({
          message: response.msg,
          type: "warning",
        });
        this.$refs.dragupload.clearFiles();
      }
    },
    // 导出内容
    async exportNotebyid() {
      await exportnoteDatabyid({ annexIds: this.multipleSelection }).then(
        (res) => {
          if (res.code === 1000) {
            const link = document.createElement("a");
            link.href = res.data;
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        }
      );
    },
    // 导出内容
    async exportNote() {
      await exportnoteData(this.condition).then((res) => {
        if (res.code === 1000) {
          const link = document.createElement("a");
          link.href = res.data;

          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 更新笔记
    async renewnoteDataFun() {
      await renewnoteData({ missionId: this.condition.missionId }).then(
        (res) => {
          this.$message({
            message: res.code === 1000 ? "成功更新笔记数据" : res.msg,
            type: res.code === 1000 ? "success" : "warning",
          });
        }
      );
    },
    async deletenoteData(val) {
      deletenoteData({ annexId: val }).then((res) => {
        this.$message({
          message: res.code === 1000 ? "成功删除笔记" : res.msg,
          type: res.code === 1000 ? "success" : "warning",
        });
        this.searchNote();
      });
    },
    editNote(val) {
      this.editnote.id = val.id;
      this.editnote.category = val.category;
      this.editnote.fansLevel = val.fansLevel;
      this.editnote.reportStatus = val.reportStatus;
      this.editnote.missionId = val.missionId;
      this.showedit = true;
    },
    editnoteData() {
      updatenoteData(this.editnote).then((res) => {
        this.$message({
          message: res.code === 1000 ? "成功更新笔记" : res.msg,
          type: res.code === 1000 ? "success" : "warning",
        });
        this.showedit = false;
        this.searchNote();
      });
    },
    async detail(val) {
      this.showdetail = true;
      this.echartData.name = val.missionTitle;
      this.echartData.id = val.id;
      await this.gethistoryData();
    },
    async gethistoryData() {
      this.echartData.xdata = [];
      this.echartData.totalNum = [];
      this.echartData.likeNum = [];
      this.echartData.commentNum = [];
      this.echartData.collectNum = [];
      await getnotehistroy({
        annexId: this.echartData.id,
        startAt: this.echartData.echartDate[0],
        endAt: this.echartData.echartDate[1],
      }).then((res) => {
        this.handleData(res.data.annexRecordVos);
      });
      this.$nextTick(() => {
        this.initechart();
      });
    },
    handleData(data) {
      data.map((res) => {
        this.echartData.xdata.push(moment(res.createdOn).format("MM-DD"));
        this.echartData.totalNum.push(res.totalNum);
        this.echartData.likeNum.push(res.likeNum);
        this.echartData.commentNum.push(res.commentNum);
        this.echartData.collectNum.push(res.collectNum);
      });
    },
    // 初始化echart
    initechart() {
      var chartDom = document.getElementById("myechart");
      var myChart = echarts.init(chartDom);
      var option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          textStyle: {
            color: "white", //设置文字颜色
          },
          extraCssText:
            "width:150px;height:auto; border:none;background: linear-gradient(to right, #e4a0fe, #a097ff);color:#fff",
        },
        color: ["#CC01FF", "#00CEE0", "#2C22FF", "#FF6500"],
        legend: {
          data: ["总互动数", "点赞数", "评论数", "收藏数"],
          itemHeight: 10,
          itemWidth: 10,
          x: "center",
          y: "top",
          top: 10,
          height: 30,
          icon: "circle",
          textStyle: {
            fontSize: 12,
            lineHeight: 12,
            rich: {
              a: {
                verticalAlign: "middle",
              },
            },
            padding: [0, 0, -2, 0], //[上、右、下、左]
          },
        },
        dataZoom: [],
        grid: {
          left: "3%",
          right: "3%",
          bottom: 10,
          top: 40,
          containLabel: true,
        },
        toolbox: {
          show: false,
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: this.echartData.xdata,
          // name: xname,
          nameLocation: "middle",
          // x轴样式
          axisLine: {
            onZero: false,
            lineStyle: {
              color: "#666666",
              fontSize: "0.8rem",
            },
          },
          axisLabel: {
            formatter: function (value) {
              return value + "";
            },
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: "value",
          // name: yname,
          // 居中
          nameLocation: "end",
          nameTextStyle: {
            padding: [0, 0, 0, 30], // 上右下左与原位置距离
          },
          // 坐标轴名称与轴线之间的距离。
          nameGap: 10,
          // y轴样式
          splitLine: {
            lineStyle: {
              type: "solid", // y轴分割线类型
              color: "#E5E6EB",
            },
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: "#909399 ",
            },
          },
        },
        series: [
          {
            name: "总互动数",
            type: "line",
            // showSymbol: false,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 3, // 0.1的线条是非常细的了
                },
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#CC01FF", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#ffffff", // 100% 处的颜色
                },
              ]),
            },
            data: this.echartData.totalNum,
          },
          {
            name: "点赞数",
            type: "line",
            // showSymbol: false,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 3, // 0.1的线条是非常细的了
                },
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#00CEE0", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#ffffff", // 100% 处的颜色
                },
              ]),
            },
            data: this.echartData.likeNum,
          },
          {
            name: "评论数",
            type: "line",
            // showSymbol: false,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 3, // 0.1的线条是非常细的了
                },
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#2C22FF", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "rgba(0,0,0,0.05)", // 100% 处的颜色
                },
              ]),
            },
            data: this.echartData.commentNum,
          },
          {
            name: "收藏数",
            type: "line",
            // showSymbol: false,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 3, // 0.1的线条是非常细的了
                },
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#FF6500", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#ffffff", // 100% 处的颜色
                },
              ]),
            },
            data: this.echartData.collectNum,
          },
        ],
      };

      if (this.echartData.xdata.length > 32) {
        option.dataZoom.push(
          {
            type: "slider",
            start: 35,
            end: 65,
          },
          {
            type: "inside",
            start: 35,
            end: 65,
          }
        );
      } else {
        option.dataZoom = [];
      }
      myChart.clear();
      option && myChart.setOption(option);
      myChart.resize();
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val.map((res) => {
        return res.id;
      });
      console.log(
        "🚀 ~ file: noteMgt.vue:1180 ~ this.multipleSelection=val.map ~  this.multipleSelection:",
        this.multipleSelection
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.note {
  padding: 10px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .condition {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;

    .conitem {
      display: flex;
      align-items: center;
      flex: 1;
      .select {
        flex: 1;
        margin: 0 16px;
      }
    }
    .conitemlarge {
      display: flex;
      align-items: center;

      .select {
        flex: 1;
        margin: 0 16px;
      }
      .input {
        width: 70px;
        margin: 0 4px;
      }
    }
  }
  .noteList {
    flex: 1;
    margin-top: 10px;
    ::v-deep .el-card__body {
      height: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
    }
    .title {
      display: flex;
      align-items: center;
      .first {
        font-size: 16px;
        font-weight: bold;
        margin-right: 10px;
      }
      .btngroup {
        margin-left: auto;
        ::v-deep .el-button {
          border-color: #5874ef;
          color: #5874ef;
          font-size: 12px !important;
        }
        ::v-deep .el-button.is-plain:focus,
        .el-button.is-plain:hover {
          color: white;
          background: #5874ef;
        }
      }
    }
    .body {
      flex: 1;
      margin-top: 10px;
      overflow: hidden;
      ::v-deep .el-table__header {
        // 使表格兼容safari，不错位,表头宽度较小
        width: 100%;
        table-layout: fixed !important;
      }
      ::v-deep .el-table__body {
        width: 100%;
        // 使表格兼容safari，不错位
        table-layout: fixed !important;
      }
    }
    .footer {
      display: flex;
      font-size: 14px;
      color: #999999;
      margin-top: 10px;
      align-items: center;
      span{
        margin-right:10px
        
      }
    }
  }
}
</style>
<style lang="scss">
.importdialog {
  .el-dialog__header {
    padding: 20px 0;
    background-color: #5874ef;
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .elupload {
    width: 100%;
    .el-upload {
      width: 100%;
    }
    .el-upload-dragger {
      width: 100%;
    }
  }
}
.el-icon-close:before {
  color: #ffffff;
}
</style>